import { ISelectOption } from '~/core/@types/global'
import { LucideIconName } from '~/core/ui/IconWrapper'

export const OPTION_SORT_CREATE_AT_CANDIDATE: ISelectOption[] = [
  {
    value: 'desc',
    label: 'desc',
    icon: 'ArrowDown'
  },
  {
    value: 'asc',
    label: 'asc',
    icon: 'ArrowUp'
  }
]
export const DESC_SORTING = 'desc'
export const ASC_SORTING = 'asc'
export const OVERVIEW_TAB = 'overview'
export const FILE_TAB = 'files'
export const JOBS_TAB = 'jobs'
export const INTERVIEWS_TAB = 'interviews'
export const EMAIL_TAB = 'messages'
export const ACTIVITY_TAB = 'activities'
export const NOTE_TAB = 'notes'
export const TASK_TAB = 'tasks'
export const FEEDBACK_TAB = 'feedback'
export const RECOMMEND_TAB = 'recommend'

export const ListSuggestNoticeOfPeriod = [
  { value: '30' },
  { value: '45' },
  { value: '60' }
]
export const DEFAULT_CURRENCY = 'USD'
export const DEFAULT_CURRENCY_NAME = 'US Dollar'
export const LIMIT_JOB_SHOW = 5

export const FIELDS_USER_SETTING_DISPLAY: {
  [key: string]: { key: string; iconMenus: LucideIconName }
} = {
  fullName: {
    key: 'fullName',
    iconMenus: 'User'
  },
  headline: {
    key: 'headline',
    iconMenus: 'Bookmark'
  },
  email: {
    key: 'email',
    iconMenus: 'Mail'
  },
  phoneNumber: {
    key: 'phoneNumber',
    iconMenus: 'Phone'
  },
  location: {
    key: 'location',
    iconMenus: 'MapPin'
  },
  links: {
    key: 'links',
    iconMenus: 'Globe'
  },
  resume: {
    key: 'cv',
    iconMenus: 'FileText'
  },
  tag: {
    key: 'tags',
    iconMenus: 'Tag'
  },
  owner: {
    key: 'owner',
    iconMenus: 'UserCog'
  },
  jobs: {
    key: 'jobs',
    iconMenus: 'Briefcase'
  },
  stage: {
    key: 'stage',
    iconMenus: 'Database'
  },
  createdAt: {
    key: 'createdAt',
    iconMenus: 'Clock'
  },
  lastActivity: {
    key: 'lastActivity',
    iconMenus: 'Zap'
  },
  applicantDisqualified: {
    key: 'disqualifyCandidates',
    iconMenus: 'Zap'
  },
  jobArchived: {
    key: 'archivedJobs',
    iconMenus: 'Zap'
  },
  talentPool: {
    key: 'talentPools',
    iconMenus: 'FolderSearch'
  }
}

export const totalYoeOptions: ISelectOption[] = Array.from(
  Array(52).keys()
).map((item) => ({
  value: String(item)
}))

export const PROFILE_CANDIDATE_TAB = {
  profile: 'profile',
  jobRelated: 'jobRelated'
}

export const CANDIDATE_STATUS_FILTER: ISelectOption[] = [
  { value: 'qualified' },
  { value: 'disqualified' },
  { value: 'new' }
]

export const CANDIDATE_CV_FILTER: ISelectOption[] = [
  { value: 'empty' },
  { value: 'not_empty' }
]

export const FILTER_CANDIDATE_FIELDS_VALUE = {
  jobId: 'jobId',
  applicantStatuses: 'applicantStatuses',
  stageTypeId: 'stageTypeId',
  tagIds: 'tagIds',
  tagOperator: 'tagOperator',
  ownerIds: 'ownerIds',
  profileTalentPoolIds: 'profileTalentPoolIds',
  countryStateId: 'countryStateId',
  profileLevel: 'profileLevel',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  profileCvsEmpty: 'profileCvsEmpty'
}
